import { Product, AnyProduct, ImageProduct, VideoProduct, indexTypeEnum } from '../../types/types';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
import { addFavourite, removeFavourite } from '../../slices/favouritesSlice';
import { Image, notification, Slider } from 'antd';
import { HeartFilled, ExclamationCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import ProductCard from '../product/productCard';
import './resultsDisplay.css';
import Loading from '../loading/loading';
import { addToCart } from '../../slices/cartSlice';
import { useEffect, useState } from 'react';
import ImageTile from '../product/ImageProduct/imageTile';

const ResultsDisplay = () => {
  const { indexType, gridType } = useSelector(
    (state: RootState) => state.demoSelector.demoSettings,
  );
  const searchResults = useSelector((state: RootState) => state.results.products);
  const status = useSelector((state: RootState) => state.results.status);
  const error = useSelector((state: RootState) => state.results.error);
  const favouriteTerms = useSelector((state: RootState) => state.favourites.products);
  const cart = useSelector((state: RootState) => state.cart.products);

  const [imageWidth, setImageWidth] = useState<number>(260);
  const [resultDisplayClass, setResultDisplayClass] = useState<string>('result-display');

  const [api, contextHolder] = notification.useNotification();

  const isLoading = status === 'loading';

  const dispatch = useDispatch();

  useEffect(() => {
    if (gridType === 'tiledGrid') {
      setResultDisplayClass('result-display-tiled');
    } else {
      setResultDisplayClass('result-display');
    }
  }, [gridType]);

  const handleActionImageError = (content: AnyProduct) => () => {
    api['warning']({
      message: 'Warning!',
      description: (
        <>
          <ExclamationCircleOutlined style={{ color: '#020659' }} />
          <span className="notification-image-error">
            {' '}
            The image for {content.name} could not be loaded. Please try again later.
          </span>
        </>
      ),
      placement: 'bottomRight',
    });
    dispatch(removeFavourite(content));
  };

  function getNotificationContent(content: AnyProduct, type: string) {
    let notificationContent = <div></div>;
    if (type === 'image') {
      notificationContent = (
        <Image
          className="notification-image"
          src={(content as ImageProduct).image_url}
          alt={'Product Image'}
          width={200}
          height={200}
          onError={handleActionImageError(content)}
        />
      );
    } else if (type === 'video') {
      notificationContent = (
        <video
          width="200"
          controls
          src={(content as VideoProduct).video_url}
          onError={handleActionImageError(content)}
        >
          Your browser does not support the video tag.
        </video>
      );
    } else if (type === 'audio') {
      notificationContent = <div>{content.name}</div>;
    }
    return notificationContent;
  }

  const handleNewFavourite = (content: AnyProduct, type: string) => {
    if (favouriteTerms.length >= 10) {
      openFavouriteFullErrorNotification();
      return;
    }

    if (favouriteTerms.includes(content)) {
      openActionWarningNotification('favourite');
      return;
    }

    if (type === 'name' || type === 'text') {
      openNotification(<>{content.name}</>, 'favourite');
    } else {
      let notificationContent = getNotificationContent(content, type);
      openNotification(notificationContent, 'favourite');
    }
    dispatch(addFavourite(content));
  };

  const handleAddToCart = (content: AnyProduct, type: string) => {
    if (cart.includes(content)) {
      openActionWarningNotification('atc');
      return;
    }

    if (type === 'name' || type === 'text') {
      openNotification(<>{content.name}</>, 'atc');
    } else {
      let notificationContent = getNotificationContent(content, type);
      openNotification(notificationContent, 'atc');
    }
    dispatch(addToCart(content));
  };

  const openNotification = (description: JSX.Element, action: 'atc' | 'favourite') => {
    const message = action === 'atc' ? 'Added to cart!' : 'Item favourited!';
    const icon =
      action === 'atc' ? (
        <ShoppingCartOutlined style={{ color: '#020659' }} />
      ) : (
        <HeartFilled style={{ color: '#020659' }} />
      );
    api.open({
      message: message,
      description: description,
      icon: icon,
      placement: 'bottomRight',
    });
  };

  const openActionWarningNotification = (actionType: 'atc' | 'favourite') => {
    const action = actionType === 'atc' ? 'added to cart' : 'favourited';
    api['warning']({
      message: 'Warning!',
      description: `This item is already ${action}!`,
      placement: 'bottomRight',
    });
  };

  const openFavouriteFullErrorNotification = () => {
    api['error']({
      message: 'Error!',
      description: 'The maximum number of favourites is 10.',
      placement: 'bottomRight',
    });
  };

  function getProductDisplay(
    product: Product,
    key: string,
    onFavourite: (content: AnyProduct, type: string) => void,
    onAddToCart: (content: AnyProduct, type: string) => void,
    curIndexType: indexTypeEnum,
  ) {
    if (product.hasOwnProperty('image_url') && gridType === 'tiledGrid') {
      return (
        <ImageTile
          key={key}
          product={product as ImageProduct}
          onFavourite={onFavourite}
          onAddToCart={onAddToCart}
          imageWidth={imageWidth}
        />
      );
    }
    return (
      <ProductCard
        key={key}
        product={product as Product}
        onFavourite={onFavourite}
        onAddToCart={onAddToCart}
        curIndexType={curIndexType}
      />
    );
  }

  const handleImageWidthChange = (width: number) => {
    setImageWidth(width);
  };

  return (
    <div className="search-results">
      {contextHolder}
      <div className={`${resultDisplayClass} ${isLoading ? 'loading' : ''}`}>
        {isLoading ? (
          <Loading />
        ) : error ? (
          <div className="error">Error: {error}</div>
        ) : (
          searchResults.map((result: Product) =>
            getProductDisplay(result, result.id, handleNewFavourite, handleAddToCart, indexType),
          )
        )}
      </div>
    </div>
  );
};

export default ResultsDisplay;
