import { API_BASE_URL } from '../constants';
import { ShoppingListSolverRequest, ShoppingListSolverResponse } from '../types/types';

export const fetchShoppingListSolution = async (params: ShoppingListSolverRequest) => {
  const response = await fetch(API_BASE_URL + '/solve_cart', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({
      queries: params.queries,
      demoID: params.demoID,
      searchSettings: params.searchSettings,
      advancedSettings: params.advancedSettings,
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data: ShoppingListSolverResponse = await response.json();
  return data;
};
