import { ImageProduct, TextProduct, VideoProduct, indexTypeEnum } from '../../types/types';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import './recommendationPanel.css';
import { Button, Input, Slider, Space } from 'antd';
import {
  fetchComplementaryRecommendations,
  fetchSimilarRecommendations,
  setSelectedProduct,
  setShowRecommendations,
} from '../../slices/recommendationsSlice';
import Loading from '../loading/loading';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { ImageGrid, TextGrid, VideoGrid } from './itemGrids';

interface RecommendationPanelProps {
  displayed: boolean;
}

const RecommendationPanel = ({ displayed }: RecommendationPanelProps) => {
  const recommendations = useSelector((state: RootState) => state.recommendations);
  const indexType = useSelector((state: RootState) => state.demoSelector.demoSettings.indexType);

  const recLimit = 40;

  const [terms, setTerms] = useState<string | null>(null);
  const [diversity, setDiversity] = useState<number>(0);
  const [tempDiversity, setTempDiversity] = useState<number>(0);

  const advancedSettings = useSelector((state: RootState) => state.advancedSettings);

  const dispatch = useDispatch<AppDispatch>();

  const demoID = useSelector((state: RootState) => state.demoSelector.demoID);

  useEffect(() => {
    handleRecommend();
  }, [diversity]);

  const handleRecommend = () => {
    if (recommendations.selectedProduct !== null && terms === null) {
      dispatch(
        fetchSimilarRecommendations({
          product: recommendations.selectedProduct,
          demoID: demoID,
          limit: recLimit,
          diversity: diversity,
        }),
      );
      ReactGA.event('execute_recommend_similar', {
        recommend_product_id: recommendations.selectedProduct.id,
        recommend_product_name: recommendations.selectedProduct.name,
        recommend_diversity: diversity,
        demo_id: demoID,
      });
    }
    if (recommendations.selectedProduct !== null && terms !== null) {
      dispatch(
        fetchComplementaryRecommendations({
          product: recommendations.selectedProduct,
          terms: terms,
          demoID: demoID,
          limit: recLimit,
          advancedSettings: advancedSettings,
        }),
      );
      ReactGA.event('execute_recommend_complementary', {
        recommend_product_id: recommendations.selectedProduct.id,
        recommend_product_name: recommendations.selectedProduct.name,
        demo_id: demoID,
      });
    }
  };

  useEffect(() => {
    handleRecommend();
  }, [recommendations.selectedProduct]);

  const handleDiversityChange = (value: number) => {
    setTempDiversity(value);
  };

  const handleDiversityDone = (value: number) => {
    setDiversity(value);
  };

  const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setTerms(null);
      return;
    }
    setTerms(e.target.value);
  };

  if (!displayed) {
    return <></>;
  }

  if (indexType === indexTypeEnum.TEXT) {
    return (
      <div>
        {recommendations.status === 'loading' && (
          <div className="loading-recommendations">
            <Loading />
          </div>
        )}
        <TextGrid
          selectedId={
            recommendations.selectedProduct === null ? '' : recommendations.selectedProduct.id
          }
          products={recommendations.recommendations as TextProduct[]}
        />
      </div>
    );
  } else {
    let itemGrid = <></>;
    if (indexType == indexTypeEnum.MULTIMODAL_IMAGE_TEXT) {
      itemGrid = (
        <ImageGrid
          selectedId={
            recommendations.selectedProduct === null ? '' : recommendations.selectedProduct.id
          }
          products={recommendations.recommendations as ImageProduct[]}
        />
      );
    } else if (indexType == indexTypeEnum.MULTIMODAL_VIDEO_TEXT) {
      itemGrid = (
        <VideoGrid
          selectedId={
            recommendations.selectedProduct === null ? '' : recommendations.selectedProduct.id
          }
          products={recommendations.recommendations as VideoProduct[]}
        />
      );
    }
    return (
      <div>
        <div>
          {/* <Space.Compact style={{ width: '100%' }}>
          <Input placeholder="Complementary terms..." onChange={handleTermsChange} />
          <Button onClick={handleRecommend} type="primary">Recommend</Button>
          </Space.Compact> */}
          <div className="diversity-slider-container">
            <div>Recommendation Diversity:</div>
            <div className="diversity-slider">
              <Slider
                min={0}
                max={100}
                step={1}
                value={tempDiversity}
                onChange={handleDiversityChange}
                onAfterChange={handleDiversityDone}
              />
            </div>
            <div>{tempDiversity}</div>
          </div>
        </div>
        {recommendations.status === 'loading' && (
          <div className="loading-recommendations">
            <Loading />
          </div>
        )}
        {itemGrid}
      </div>
    );
  }
};

export default RecommendationPanel;
