import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Space } from 'antd';
import ReactGA from 'react-ga4';
import ResultsDisplay from './results/resultsDisplay';
import SearchBar from './search/searchBar';
import { AppDispatch, RootState } from '../store/store';
import SearchSettingsModal from './searchSettings/searchSettingsModal';
import FavouriteTags from './favourites/favouriteTags';
import CustomInstructionModal from './customInstructions/customInstructionsModal';
import StyleSelector from './styleSelector/styleSelector';
import RecommendationModal from './recommendations/recommendationModal';
import ContactUs from './contactUs/contactUs';
import StyleManagerModal from './styleManagement/styleManagerModal';
import CreateStyleModal from './styleManagement/createStyleModal';
import WebsiteExtractionModal from './websiteExtraction/websiteExtractionModal';
import { setLexicalRRF, setSearchType } from '../slices/advancedSettingsSlice';

import './demoUI.css';
import LiveRecommendations from './liveRecommendations/liveRecommendations';
import { indexTypeEnum } from '../types/types';
import Cart from './cart/cart';
import Loading from './loading/loading';
import ChatModal from './chat/chatModal';
import ShoppingListSolver from './shoppingListSolver/shoppingListSolver';

const DemoUI = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const dispatch = useDispatch<AppDispatch>();
  const validDemo = useSelector((state: RootState) => state.demoSelector.valid);
  const demoCheckStatus = useSelector((state: RootState) => state.demoSelector.status);
  const { demoID, demoSettings } = useSelector((state: RootState) => state.demoSelector);
  const showLiveRecommendations = useSelector(
    (state: RootState) => state.liveRecommendations.showLiveRecommendations,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (demoSettings.defaultSearchType !== undefined) {
      if (demoSettings.defaultSearchType === 'LEXICAL') {
        dispatch(setSearchType('lexical'));
        dispatch(setLexicalRRF(false));
      } else if (demoSettings.defaultSearchType === 'TENSOR') {
        dispatch(setSearchType('semantic'));
        dispatch(setLexicalRRF(false));
      } else if (demoSettings.defaultSearchType === 'RRF') {
        dispatch(setSearchType('semantic'));
        dispatch(setLexicalRRF(true));
      }
    } else {
      dispatch(setSearchType('semantic'));
      dispatch(setLexicalRRF(false));
    }
  }, [demoID, demoSettings]);

  useEffect(() => {
    ReactGA.event('execute_load_demo', {
      demo_id: demoID,
    });
  }, [demoID]);

  return (
    <>
      {validDemo && (
        <>
          <div className="search-settings">
            <Space
              size={isMobile ? 'small' : 'large'}
              direction={isMobile ? 'vertical' : 'horizontal'}
            >
              {demoSettings.showWebsiteSearchExtractor && <WebsiteExtractionModal />}
              {demoSettings.showCustomInstructions && <CustomInstructionModal />}
              {demoSettings.showSearchSettings && <SearchSettingsModal />}
              {demoSettings.showFavourites && <FavouriteTags />}
              {demoSettings.showStyle && <StyleSelector />}
              {demoSettings.showStyle && <StyleManagerModal />}
              {/* <ChatModal /> */}
              {demoSettings.showChat && <ChatModal />}
              {demoSettings.showCart && <Cart />}
              {demoSettings.showMagicMatch && <ShoppingListSolver />}
            </Space>
          </div>
          <SearchBar
            enableMoreOf={demoSettings.showMoreOf}
            enableLessOf={demoSettings.showLessOf}
            filterableAttributes={demoSettings.filterableAttributes}
            filterConfig={demoSettings.filterConfig}
            scoreModifierAttributes={demoSettings.scoreModifierAttributes}
          />
          {demoSettings.indexType === indexTypeEnum.MULTIMODAL_IMAGE_TEXT &&
            showLiveRecommendations && <LiveRecommendations />}
          <ResultsDisplay />
          <RecommendationModal />
          <CreateStyleModal />
        </>
      )}
      {!validDemo && demoID.length > 0 && demoCheckStatus !== 'loading' && <ContactUs />}
      {demoCheckStatus === 'loading' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <Loading />
        </div>
      )}
    </>
  );
};

export default DemoUI;
